<template>
  <div class="subscribe-container">
    <h3 style="text-align: left;">课程订阅</h3>
    <div class="pay-item" style="margin-top: 15px">
      <div class="flex-row">
        <p class="pay-item-title" style="flex: 1">费用</p>
        <p class="pay-item-content">199元/年</p>
      </div>
      <div class="flex-row" style="margin-top: 15px">
        <p class="pay-item-title" style="flex: 1">开始时间</p>
        <p class="pay-item-content">{{ showStartTime }}</p>
      </div>
      <div class="flex-row" style="margin-top: 15px">
        <p class="pay-item-title" style="flex: 1">结束时间</p>
        <p class="pay-item-content">{{ showEndTime }}</p>
      </div>
      <div class="flex-row" style="margin-top: 15px">
        <p class="pay-item-title" style="flex: 1">订阅时长</p>
        <p class="pay-item-content">{{ '1年' }}</p>
      </div>
    </div>
    <div class="btnGotoPay" @click="gotoPay">立即支付</div>
  </div>
</template>

<script>
import {formatDate} from "../../../utils/date";
import {
  createSubscribeOrder,
  getSignPackageFWH,
  startWXPayForCourseSubscribe
} from "../../../api/keneng";

export default {
  name: "Subscribe",
  data() {
    return {
      user_id: this.$route.query.user_id,
      startTime: '',
      endTime: '',
      showStartTime: '',
      showEndTime: '',
    }
  },
  mounted() {
    let date = new Date()
    let currentYear = date.getFullYear()
    this.showStartTime = formatDate(new Date(date.getTime()), 'yyyy.MM.dd')
    let nextYear = currentYear + 1
    date.setFullYear(nextYear)
    this.showEndTime = formatDate(new Date(date.getTime()), 'yyyy.MM.dd')

  },
  methods: {

    gotoPay() {
      //创建订单
      createSubscribeOrder(this.user_id).then((res) => {
        let order = res.data.data
        let order_id = order._id.$id
        this.doCreateWXPayParams(order_id)
      })
    },
    doCreateWXPayParams(order_id) {
      startWXPayForCourseSubscribe(order_id).then((res) => {
        let result = res.data
        if (result.code == 0) {
          this.showWXPay(result.data.timeStamp, result.data.nonceStr, result.data.package, result.data.signType, result.data.paySign)
        } else {
          this.$message.error(result.msg)
        }
      })
    },
    showWXPay(timestamp, nonceStr, package1, signType, paySign) {
      let that = this
      getSignPackageFWH().then((res) => {
        const result = res.data
        wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: result.appId, // 必填，公众号的唯一标识
          timestamp: result.timestamp, // 必填，生成签名的时间戳
          nonceStr: result.nonceStr, // 必填，生成签名的随机串
          signature: result.signature, // 必填，签名
          jsApiList: [
            'chooseWXPay'
          ] // 必填，需要使用的JS接口列表
        })
      })
      wx.ready(() => {
        wx.chooseWXPay({
          timestamp: timestamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
          nonceStr: nonceStr, // 支付签名随机串，不长于 32 位
          package: package1, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
          signType: signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
          paySign: paySign, // 支付签名
          success: (res) => {
            // 支付成功后的回调函数
            that.$message({
              message: '缴费成功',
              type: 'success'
            })
            that.$router.go(-1)
          }
        })
      })
    }
  }
}
</script>

<style scoped lang="less">


.btnGotoPay {
  margin-top: 40px;
  text-align: center;
  width: 100%;
  cursor: pointer;
  height: 42px;
  line-height: 42px;
  font-size: 18px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #FFFFFF;
  background: #5D5BB5;
  box-shadow: 0px 0px 24px 1px rgba(0, 0, 0, 0.05);
  border-radius: 26px 26px 26px 26px;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.pay-item {
  width: 100%;
  background: #FFFAF0;
  padding: 20px 20px;
  box-sizing: border-box;
}

.pay-item-title {
  font-size: 16px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: normal;
  color: #666666;
}

.pay-item-content {
  font-size: 16px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: normal;
  color: #333333;
}

.subscribe-container {
  background-color: white;
  width: 100%;
  height: 100vh;
  position: relative;
  overflow-y: auto;
  padding: 10px 20px 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}


</style>
